




























































































import LangModule from "@/store/LangModule";
import {Component, Vue} from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

@Component
export default class CompanyView extends Vue {
	lang: any = getModule(LangModule).lang
	brands = [
		{url: require("@/assets/perfect_beauty.png")},
		{url: require("@/assets/brands/6.png")},
		{url: require("@/assets/brands/15.png")},
		{url: require("@/assets/brands/3.png")},
		{url: require("@/assets/brands/2.png")},
		{url: require("@/assets/brands/4.png")},
		{url: require("@/assets/brands/5.png")},
		{url: require("@/assets/brands/7.png")},
		{url: require("@/assets/brands/1.png")},
		{url: require("@/assets/brands/8.png")},
		{url: require("@/assets/brands/9.png")},
		{url: require("@/assets/brands/10.png")},
		{url: require("@/assets/brands/11.png")},
		{url: require("@/assets/brands/12.png")},
		{url: require("@/assets/brands/13.png")},
		{url: require("@/assets/brands/14.png")},
		{url: require("@/assets/brands/16.png")},
		{url: require("@/assets/brands/17.png")},
		{url: require("@/assets/brands/18.png")},
		{url: require("@/assets/brands/19.png")},
		{url: require("@/assets/brands/20.png")},
		{url: require("@/assets/brands/21.png")},
		{url: require("@/assets/brands/22.png")},
		{url: require("@/assets/brands/23.png")},
		{url: require("@/assets/brands/24.png")},
		{url: require("@/assets/brands/25.png")},
	]
}
